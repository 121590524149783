<template>
    <div class="fullscreen-container" :class="{ 'safari-mobile': isSafari }" :style="{ height: currentHeight + 'px' }">
        <div ref="viewerContainer" class="viewer-container" :style="{ height: currentHeight + 'px' }">
            <!-- 加载遮罩层 -->
            <transition name="fade">
                <div v-if="loading" class="loading-overlay">
                    <div class="loading-text">{{ loadingText }}</div>
                </div>
            </transition>
            <!-- 错误提示遮罩层 -->
            <transition name="fade">
                <div v-if="loadError" class="error-overlay">
                    <div class="error-text">加载失败，请重试或选择其他点位。</div>
                    <button @click="retryLoad" class="retry-btn">重试</button>
                </div>
            </transition>
        </div>

        <!-- 信息弹窗（统一放在最外层保证移动端可见） -->
        <transition name="popup">
            <div v-if="showInfoPopup" class="info-popup">
                <div class="info-popup-content">
                    <button class="close-btn" @click="toggleInfoPopup(false)">×</button>
                    <p class="info-text">{{ currentSelectedPoint.info }}</p>
                    <p class="info-tips" style="font-size: 10px; color: gray;">再次点按详细信息按钮可以关闭这个窗口。</p>
                </div>
            </div>
        </transition>

        <!-- 移动端底栏 -->
        <div v-if="!isDesktop" class="mobile-bottom-bar">
            <span class="powered-by">VDS AuroraView</span>
            <div class="buttons">
                <transition name="fade">
                    <button class="info-btn" @click="toggleInfoPopup" :disabled="!hasInfo"
                        :class="{ 'no-info': !hasInfo, 'highlight': highlight }" key="info-button">
                        <span>{{ hasInfo ? '此位置的详细信息' : '无可用操作' }}</span>
                    </button>
                </transition>
            </div>
        </div>

        <!-- 移动端显示的侧边栏打开按钮 -->
        <button class="mobile-toggle-btn" @click="togglePanel" :class="{ 'hint-active': showMobileHint }">
            <span class="bar"></span>
            <span class="bar"></span>
            <span class="bar"></span>
            <transition name="fade">
                <span v-if="showMobileHint" class="mobile-toggle-hint">点按这里可以切换不同位置</span>
            </transition>
        </button>

        <!-- 右侧点位面板 -->
        <transition name="slide-right">
            <div class="right-panel" v-if="panelVisible" :style="{ height: currentHeight + 'px' }">
                <div class="scroll-content">
                    <h1 class="panel-title">房间和位置</h1>
                    <p class="panel-light">VDS Studio Nanjing</p>

                    <div v-for="(floor, fIndex) in floors" :key="fIndex" class="floor-section">
                        <h2 class="floor-title">{{ floor.floorTitle }}</h2>
                        <ul class="point-list">
                            <li v-for="point in floor.points" :key="point.id" class="point-item">
                                <div class="point-header" :class="{ selected: point.id === selectedPointId }"
                                    @click="handlePointClick(point)">
                                    <div class="point-thumbnail">
                                        <img :src="point.thumbnail" alt="point thumbnail" />
                                    </div>
                                    <div class="point-info">
                                        <p class="point-title">{{ point.title }}</p>
                                        <p class="point-desc">{{ point.description }}</p>
                                    </div>
                                    <div v-if="point.subPoints && point.subPoints.length > 0" class="point-toggle-icon">
                                        <i :class="point.expanded ? 'arrow up' : 'arrow down'"></i>
                                    </div>
                                </div>
                                <transition name="expand">
                                    <ul v-if="point.expanded && point.subPoints && point.subPoints.length > 0"
                                        class="sub-point-list">
                                        <li v-for="sub in point.subPoints" :key="sub.id" class="sub-point-item"
                                            :class="{ selected: sub.id === selectedPointId }"
                                            @click.stop="selectSubPoint(point, sub)">
                                            <div class="sub-thumbnail">
                                                <img :src="sub.thumbnail" alt="sub point thumbnail" />
                                            </div>
                                            <p class="sub-title">{{ sub.title }}</p>
                                        </li>
                                    </ul>
                                </transition>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- 桌面端底栏 -->
                <div v-if="isDesktop" class="bottom-bar">
                    <span class="powered-by">Powered by<br />VDS AuroraView</span>
                    <div class="buttons">
                        <transition name="fade">
                            <button class="info-btn" @click="toggleInfoPopup" :disabled="!hasInfo"
                                :class="{ 'no-info': !hasInfo, 'highlight': highlight }">
                                <span>{{ hasInfo ? '此位置的详细信息' : '无可用操作' }}</span>
                            </button>
                        </transition>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import Marzipano from 'marzipano';

export default {
    name: "PanoramaFullScreen",
    data() {
        return {
            viewer: null,
            currentScene: null,
            showInfoPopup: false,
            isSafari: false,
            panelVisible: true,
            selectedPointId: null,
            currentHeight: window.innerHeight,
            highlight: false,
            loading: false,
            loadError: false,
            pendingPanorama: null,
            initialLoad: true,
            lastYaw: 0,
            lastPitch: 0,
            lastFov: Math.PI / 2,
            showMobileHint: false,
            isHighPerformance: true, // 用于判断加载高画质还是低画质
            floors: [
                {
                    floorTitle: "一楼",
                    points: [
                        {
                            id: 1,
                            title: '挑高空间',
                            description: '主要的拍摄空间。',
                            thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/1F/high-ceilings/prv.JPG',
                            panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/outside/panorama.JPG',
                            panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/outside/panoramalow.JPG',
                            expanded: false,
                            subPoints: [
                                {
                                    id: '1-1',
                                    title: '视角1-中心',
                                    thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/1F/high-ceilings/center/prv.jpeg',
                                    panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/1F/high-ceilings/center/panorama.JPG',
                                    panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/1F/high-ceilings/center/panoramalow.JPG',
                                    info: "这里是整个场地的核心拍摄区域，拥有9米挑高，宽敞而灵活，足以容纳任何创意的实现。从复杂的舞台布景到多角度灯光设置，都没问题。地面空间同样宽广，不管是大型设备的布置，还是直接引入车辆拍摄，都能游刃有余。",
                                },
                                {
                                    id: '1-2',
                                    title: '视角2-靠前',
                                    thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/1F/high-ceilings/front/prv.jpeg',
                                    panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/1F/high-ceilings/front/panorama.JPG',
                                    panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/1F/high-ceilings/front/panoramalow.JPG'
                                },
                                {
                                    id: '1-3',
                                    title: '视角3-靠后',
                                    thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/1F/high-ceilings/back/prv.jpeg',
                                    panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/1F/high-ceilings/back/panorama.JPG',
                                    panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/1F/high-ceilings/back/panoramalow.JPG'
                                },
                                {
                                    id: '1-4',
                                    title: '视角4-极后',
                                    thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/1F/high-ceilings/so-back/prv.jpeg',
                                    panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/1F/high-ceilings/so-back/panorama.JPG',
                                    panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/1F/high-ceilings/so-back/panoramalow.JPG',
                                    info: "这个区域相比前方挑高稍低，但依然宽敞，适合作为设备放置区或拍摄准备区。视图朝左，是一个舒适的综合会客厅；朝右则是男女卫生间，满足工作间隙的需求。这一区域和核心空间相辅相成，让整个场地更加功能完善，使用起来也能更加流畅。",
                                }
                            ]
                        },
                        {
                            id: 2,
                            title: '会客厅',
                            description: '全能的会客和综合会议空间。',
                            thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/1F/meeting-room/prv.JPG',
                            panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/outside/panorama.JPG',
                            panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/outside/panoramalow.JPG',
                            expanded: false,
                            subPoints: [
                                {
                                    id: '2-1',
                                    title: '未拉上窗帘',
                                    thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/1F/meeting-room/curtain-not-drawn/prv.jpeg',
                                    panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/1F/meeting-room/curtain-not-drawn/panorama.JPG',
                                    panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/1F/meeting-room/curtain-not-drawn/panoramalow.JPG',
                                    info: "会客厅是一个全能的会客与综合会议空间，专为灵活多用途而设计。这里既可以作为开放空间使用，适合自由的沟通和团队协作；也可以通过拉上窗帘，瞬间变成一个专注的封闭空间，确保不被外界干扰，无论是重要的会议还是深度的创意讨论，都能轻松适应。这个空间内配备了一台电视，默认支持Apple AirPlay投屏服务，无需复杂连接，随时分享画面内容。同时配备有一台3P空调用来调节温度。你可以切换视角，拉上窗帘看看。"
                                },
                                {
                                    id: '2-2',
                                    title: '已拉上窗帘',
                                    thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/1F/meeting-room/curtain-drawn/prv.jpeg',
                                    panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/1F/meeting-room/curtain-drawn/panorama.JPG',
                                    panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/1F/meeting-room/curtain-drawn/panoramalow.JPG'
                                }
                            ]
                        },
                        {
                            id: 3,
                            title: '机房',
                            description: 'Studio Nanjing的智慧大脑。',
                            thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/1F/server-room/prv.jpeg',
                            panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/1F/server-room/panorama.JPG',
                            panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/1F/server-room/panoramalow.JPG',
                            info: '机房是Studio Nanjing的智慧大脑，为整个场地的高效运转提供坚实的技术保障。在这里，我们提供了多种电力规格供你选择，无论是大型摄影器材还是高功率的拍摄设备，都能安全稳定地运行。全场已覆盖由顶级互联网基础设施品牌支持的Wi-Fi 6无线网络，随时连接，更加流畅；同时配备至高上下载达200Mbps的互联网专线有线连接体验，为你的拍摄和直播提供无与伦比的稳定性，确保全程不中断。全棚也已经安装了语音音箱以及智能灯光和环境设备，只需喊一声语音助手，就能快速调整灯光、环境氛围和设备设置，让你更专注于创意本身。所以，这里不仅仅是一个机房，它更是让你的拍摄轻松自如的幕后英雄。',
                            expanded: false
                        }
                    ]
                },
                {
                    floorTitle: "二楼",
                    points: [
                        {
                            id: 4,
                            title: '演员休息室',
                            description: '适用于演员休息和简单会议的综合空间。',
                            thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/2F/lounge/prv.jpeg',
                            panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/2F/lounge/panorama.JPG',
                            panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/2F/lounge/panoramalow.JPG',
                            expanded: false,
                            info: '这是一个适用于多种场景的综合空间，专为演员和团队提供舒适便捷的服务设计。这里不仅可以作为拍摄间隙的放松场所，也是一个适合小型讨论和简单会议的空间。让演员和团队成员能够在繁忙的工作中找到一片安静的角落。'
                        },
                        {
                            id: 5,
                            title: '靠化妆间-楼道',
                            thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/2F/powder-room-hallway/prv.jpeg',
                            panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/2F/powder-room-hallway/panorama.JPG',
                            panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/2F/powder-room-hallway/panoramalow.JPG',
                            expanded: false,
                            info: '你现在的位置是2楼靠近化妆间的楼道，这里是化妆和造型区域的重要通道，同时连接了1楼的靠卫生间的区域。需要提醒的是，这条楼道的对面才是靠近演员休息室的楼道，如果你希望前往休息室方向，可以切换视角看看对面的区域。与对面的楼道类似，1楼有两个楼梯都能通往2楼，但所到达的区域不同，因此现实在这里时稍加注意就可以轻松找到你需要的地方。'
                        },
                        {
                            id: 6,
                            title: '化妆间',
                            description: '专注于满足基础化妆与造型需求。',
                            thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/2F/powder-room/prv.jpeg',
                            panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/2F/powder-room/panorama.JPG',
                            panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/2F/powder-room/panoramalow.JPG',
                            expanded: false,
                            info: '化妆间的设计虽然简单，但功能性十足，专注于为演员和工作人员提供最基础的化妆与造型支持。空间内配备了镜子和基本的灯光，确保化妆师和造型师能完成工作。简洁的布置不仅让环境更清爽，也为各种化妆设备和工具的摆放留出充足空间。'
                        },
                        {
                            id: 7,
                            title: '靠演员休息室-楼道',
                            thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/2F/lounge-hallway/prv.JPG',
                            panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/outside/panorama.JPG',
                            panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/outside/panoramalow.JPG',
                            expanded: false,
                            subPoints: [
                                {
                                    id: '7-1',
                                    title: '视角1',
                                    thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/2F/lounge-hallway/point1/prv.jpeg',
                                    panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/2F/lounge-hallway/point1/panorama.JPG',
                                    panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/2F/lounge-hallway/point1/panoramalow.JPG',
                                    info: '你现在所处的位置是2楼靠近演员休息室的楼道，这里连接了1楼的靠会客厅的区域与2楼的核心功能区，是整个场地动线的重要部分。需要注意的是，这条楼道的对面才是通往化妆间的楼道，如果你正在寻找的是化妆间，可以切换视角看看对面的区域。两个楼梯都能从1楼通往2楼，但它们通达的区域有所不同，因此现实在这里时记得稍加留意，确保到达你真正所需要的位置。现在，试试切换视角探索对面的化妆间楼道吧。'
                                },
                                {
                                    id: '7-2',
                                    title: '视角2',
                                    thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/2F/lounge-hallway/point2/prv.jpeg',
                                    panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/2F/lounge-hallway/point2/panorama.JPG',
                                    panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/2F/lounge-hallway/point2/panoramalow.JPG',
                                    info: '你现在所处的位置是2楼靠近演员休息室的楼道，这里连接了1楼的靠会客厅的区域与2楼的核心功能区，是整个场地动线的重要部分。需要注意的是，这条楼道的对面才是通往化妆间的楼道，如果你正在寻找的是化妆间，可以切换视角看看对面的区域。两个楼梯都能从1楼通往2楼，但它们通达的区域有所不同，因此现实在这里时记得稍加留意，确保到达你真正所需要的位置。现在，试试切换视角探索对面的化妆间楼道吧。'
                                }
                            ]
                        }
                    ]
                },
                {
                    floorTitle: "三楼",
                    points: [

                        {
                            id: 8,
                            title: 'VIP休息室',
                            description: '优雅与尊贵的专属享受。',
                            thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/3F/vip-lounge/prv.jpeg',
                            panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/3F/vip-lounge/panorama.JPG',
                            panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/3F/vip-lounge/panoramalow.JPG',
                            expanded: false,
                            info: 'VIP室是全场最宽敞的休息室。这里配备了柔软的沙发和齐全的茶歇设施，是洽谈人生、放松身心的理想场所。不论是拍摄间隙的悠闲时光，还是与团队的高效交流，这里都能满足你的需求。房间内配备了一台电视，也支持Apple AirPlay功能，无论是播放参考素材还是分享创意，都方便高效。此外，这里还安装了一台1.5P空调，确保你无论一年四季都能享受到最舒适的环境。让你远离喧嚣，尽享从容与优雅。'
                        },
                        {
                            id: 9,
                            title: '多功能休息室',
                            description: '安静、舒适的休憩。',
                            thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/3F/sleep-room/prv.JPG',
                            panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/outside/panorama.JPG',
                            panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/outside/panoramalow.JPG',
                            expanded: false,
                            subPoints: [
                                {
                                    id: '9-1',
                                    title: '沙发收起',
                                    thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/3F/sleep-room/bed-collapse/prv.jpeg',
                                    panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/3F/sleep-room/bed-collapse/panorama.JPG',
                                    panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/3F/sleep-room/bed-collapse/panoramalow.JPG',
                                    info: '多功能休息室是场地中最安静的区域，经过声学处理，具备优越的隔音效果，让你可以在这里安心休息或专注工作。房间内的沙发可轻松展开变为床，房内也配备了一张独立的桌子和椅子，为你的工作或生活提供便利。房间内安装了一台1.5P空调和一台空气净化器，为你提供清新的环境。但需要注意的是，该房间需通过Access门禁进入，保证私密性。如果你正在租赁并需要使用，请联系在场的棚管请求开门，或在租赁前就问我们获取UID临时卡。多功能休息室是一个完美的避风港，让你在忙碌中找到属于自己的安静与舒适。现在切换视角，看看沙发展开成床时的样子吧。'
                                },
                                {
                                    id: '9-2',
                                    title: '沙发展开',
                                    thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/3F/sleep-room/bed-expand/prv.jpeg',
                                    panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/3F/sleep-room/bed-expand/panorama.JPG',
                                    panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/3F/sleep-room/bed-expand/panoramalow.JPG'
                                }
                            ]
                        },
                        {
                            id: 10,
                            title: '楼道',
                            thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/3F/hallway/prv.JPG',
                            panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/outside/panorama.JPG',
                            panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/outside/panoramalow.JPG',
                            expanded: false,
                            subPoints: [
                                {
                                    id: '10-1',
                                    title: '视角1',
                                    thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/3F/hallway/point1/prv.jpeg',
                                    panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/3F/hallway/point1/panorama.JPG',
                                    panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/3F/hallway/point1/panoramalow.JPG',
                                    info: '与2楼楼道不同，3楼的设计是贯通的，打通了两条动线，为整个场地提供了更高的便利性和流畅度。这里也拥有全场最佳的全景视野。站在楼道上，就能一览整个拍摄区域的情况，轻松掌控全局。这里不仅是连接空间的重要枢纽，更是一个让你尽览场地的绝佳位置。'
                                },
                                {
                                    id: '10-2',
                                    title: '视角2',
                                    thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/3F/hallway/point2/prv.jpeg',
                                    panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/3F/hallway/point2/panorama.JPG',
                                    panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/3F/hallway/point2/panoramalow.JPG',
                                    info: '与2楼楼道不同，3楼的设计是贯通的，打通了两条动线，为整个场地提供了更高的便利性和流畅度。这里也拥有全场最佳的全景视野。站在楼道上，就能一览整个拍摄区域的情况，轻松掌控全局。这里不仅是连接空间的重要枢纽，更是一个让你尽览场地的绝佳位置。'
                                }
                            ]
                        },
                    ]
                },
                {
                    floorTitle: "四楼",
                    points: [
                        {
                            id: 11,
                            title: '马道',
                            description: '灵活悬挂灯具与设备的高空平台。',
                            thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/4F/trail/prv.JPG',
                            panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/outside/panorama.JPG',
                            panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/outside/panoramalow.JPG',
                            expanded: false,
                            subPoints: [
                                {
                                    id: '11-1',
                                    title: '视角1-入口',
                                    thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/4F/trail/entry/prv.jpeg',
                                    panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/4F/trail/entry/panorama.JPG',
                                    panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/4F/trail/entry/panoramalow.JPG',
                                    info: '马道是一个专为悬挂灯具和其他设备而设计的高空区域，提供了极大的灵活性。整个挑高区域的无线网络覆盖也由安装在马道上的三个无线接入点提供，确保了网络的稳定性和覆盖范围。在马道上操作时，格外小心，注意自己的安全，也避免触碰到这些接入点。'
                                },
                                {
                                    id: '11-2',
                                    title: '视角2-靠后',
                                    thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/4F/trail/back/prv.jpeg',
                                    panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/4F/trail/back/panorama.JPG',
                                    panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/4F/trail/back/panoramalow.JPG'
                                },
                                {
                                    id: '11-3',
                                    title: '视角3-中心',
                                    thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/4F/trail/center/prv.jpeg',
                                    panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/4F/trail/center/panorama.JPG',
                                    panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/4F/trail/center/panoramalow.JPG'
                                },
                                {
                                    id: '11-4',
                                    title: '视角4-靠前',
                                    thumbnail: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/4F/trail/front/prv.jpeg',
                                    panorama: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/4F/trail/front/panorama.JPG',
                                    panoramalow: 'https://vcos-vds.hydun.com/vds-pub-fonts/added-studionanjing/panorama/4F/trail/front/panoramalow.JPG'
                                }
                            ]
                        }
                    ]
                }
            ],
        };
    },
    computed: {
        isDesktop() {
            return window.innerWidth >= 768;
        },
        currentSelectedPoint() {
            for (let floor of this.floors) {
                for (let p of floor.points) {
                    if (p.id === this.selectedPointId) return p;
                    if (p.subPoints) {
                        for (let sp of p.subPoints) {
                            if (sp.id === this.selectedPointId) return sp;
                        }
                    }
                }
            }
            return null;
        },
        hasInfo() {
            return this.currentSelectedPoint && this.currentSelectedPoint.info;
        },
        loadingText() {
            return this.initialLoad ? "正在加载中..." : "正在传送...";
        }
    },
    watch: {
        hasInfo(newVal) {
            if (newVal) {
                this.triggerHighlight();
            }
        }
    },
    mounted() {
        document.title = "全景视图 - VDS Studio Nanjing 线下场地 - VDS·欢乐，正发生。";
        this.detectSafari();

        // 判断是否高性能设备（简单判断：苹果或PC算高性能，否则低性能）
        const ua = navigator.userAgent.toLowerCase();
        if (ua.includes('iphone') || ua.includes('ipad') || ua.includes('mac') || ua.includes('macintosh') || ua.includes('windows')) {
            this.isHighPerformance = true;
            console.log("嗷呜！狼狼看到你是高性能");
            console.log("高性能模式开了");
        } else {
            this.isHighPerformance = false;
            console.log("嗷呜！狼狼看到你是标准性能");
            console.log("标准模式开了");
        }

        this.$nextTick(() => {
            this.handleResize();
        });

        // 对移动端默认收起侧栏
        if (window.innerWidth < 768) {
            this.panelVisible = false;
        }

        const initialPoint = this.floors[0].points[0];
        this.loadPanorama(initialPoint.panorama, initialPoint.panoramalow);

        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        if (this.viewer) {
            try {
                this.viewer.destroy();
                this.viewer = null;
            } catch (error) {
                console.error("Error during viewer destruction:", error);
            }
        }
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        detectSafari() {
            const ua = navigator.userAgent;
            const isSafari = /^((?!chrome|android).)*safari/i.test(ua);
            const isMobile = /iPhone|iPad|iPod/i.test(ua);
            this.isSafari = isSafari && isMobile;
        },
        handleResize() {
            this.currentHeight = window.innerHeight - 50;
            if (this.viewer) {
                this.viewer.updateSize();
            }
        },
        togglePanel() {
            this.panelVisible = !this.panelVisible;
        },
        triggerHighlight() {
            this.highlight = true;
            setTimeout(() => {
                this.highlight = false;
            }, 500);
        },
        toggleInfoPopup(forceValue) {
            if (!this.hasInfo) return;
            if (typeof forceValue === 'boolean') {
                this.showInfoPopup = forceValue;
            } else {
                this.showInfoPopup = !this.showInfoPopup;
            }
        },
        handlePointClick(point) {
            this.collapseOtherPoints(point);
            if (point.subPoints && point.subPoints.length > 0) {
                point.expanded = !point.expanded;
            } else {
                this.selectPoint(point);
            }
        },
        collapseOtherPoints(currentPoint) {
            this.floors.forEach(floor => {
                floor.points.forEach(p => {
                    if (p !== currentPoint) {
                        p.expanded = false;
                    }
                });
            });
        },
        debugLog(message) {
            console.log("[DEBUG]", message);
        },
        selectPoint(point) {
            this.selectedPointId = point.id;
            this.loadPanorama(point.panorama, point.panoramalow);
            if (window.innerWidth < 768) {
                this.panelVisible = false;
            }
            this.showInfoPopup = false;
        },
        selectSubPoint(point, sub) {
            this.selectedPointId = sub.id;
            this.loadPanorama(sub.panorama, sub.panoramalow);
            if (window.innerWidth < 768) {
                this.panelVisible = false;
            }
            this.showInfoPopup = false;
        },
        async loadPanorama(url, urlLow) {
            this.debugLog(`Start loading panorama: ${url}`);

            this.loadError = false;
            this.loading = true;
            this.pendingPanorama = this.isHighPerformance ? url : urlLow;

            try {
                const response = await fetch(this.pendingPanorama, { method: 'HEAD' });
                if (!response.ok) {
                    throw new Error('Image not reachable');
                }
            } catch (err) {
                console.error("Image check failed:", err);
                this.loadError = true;
                this.loading = false;
                return;
            }

            if (this.currentScene) {
                const currentView = this.currentScene.view();
                this.lastYaw = currentView.yaw();
                this.lastPitch = currentView.pitch();
                this.lastFov = currentView.fov();
                this.debugLog(`Last view state: yaw=${this.lastYaw}, pitch=${this.lastPitch}, fov=${this.lastFov}`);
            }

            if (this.viewer) {
                this.viewer.destroy();
                this.viewer = null;
                this.debugLog("Viewer destroyed successfully.");
            }

            const opts = { stageType: 'webgl' };
            this.viewer = new Marzipano.Viewer(this.$refs.viewerContainer, opts);
            this.debugLog("Viewer created with WebGL stage type.");

            const source = Marzipano.ImageUrlSource.fromString(this.pendingPanorama);
            const geometry = new Marzipano.EquirectGeometry([{ width: 4000 }]);
            const limiter = Marzipano.RectilinearView.limit.traditional(4000, 120 * Math.PI / 180);

            const yaw = this.lastYaw || 0;
            const pitch = this.lastPitch || 0;
            const fov = this.lastFov || Math.PI / 2;

            const view = new Marzipano.RectilinearView({ yaw, pitch, fov }, limiter);
            const scene = this.viewer.createScene({
                source: source,
                geometry: geometry,
                view: view,
                pinFirstLevel: true
            });

            this.debugLog("Scene created successfully. Starting transition...");

            scene.switchTo({ transitionDuration: 1000 }, () => {
                if (this.pendingPanorama !== (this.isHighPerformance ? url : urlLow)) return;

                this.loading = false;
                this.currentScene = scene;
                this.pendingPanorama = null;

                this.debugLog("Scene transition completed successfully.");

                if (this.initialLoad) {
                    this.initialLoad = false;
                    // 如果是手机端，在初次加载完成后显示hint
                    if (window.innerWidth < 768) {
                        this.showMobileHint = true;
                        setTimeout(() => {
                            this.showMobileHint = false;
                        }, 5000);
                    }
                }
            });
        },
        retryLoad() {
            if (this.pendingPanorama) {
                // 根据当前isHighPerformance状态再次尝试加载
                fetch(this.pendingPanorama, { method: 'HEAD' })
                    .then(res => {
                        if (!res.ok) throw new Error('Image not reachable');
                        this.loadPanorama(this.pendingPanorama, this.pendingPanorama);
                    })
                    .catch(err => {
                        console.error("Retry failed:", err);
                        this.loadError = true;
                        this.loading = false;
                    });
            }
        }
    }
};
</script>

<style scoped>
html,
body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: 100%;
    -webkit-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: none;
    touch-action: none;
}

.fullscreen-container {
    width: 100vw;
    display: flex;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
}

.viewer-container {
    width: calc(100vw - 300px);
    background: #000;
    overflow: hidden;
    position: relative;
}

/* 加载状态遮罩 */
.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(8px);
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.loading-text {
    color: #fff;
    font-size: 1.2rem;
}

/* 错误遮罩 */
.error-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(8px);
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 11;
}

.error-text {
    color: #fff;
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.retry-btn {
    background: #fff;
    color: #000;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.retry-btn:hover {
    background: #ddd;
}

.mobile-bottom-bar {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    overflow: hidden;
}

.right-panel {
    width: 300px;
    background: #000;
    color: #fff;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 2;
}

.scroll-content {
    flex: 1;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 20px;
    padding-bottom: 80px;
}

.panel-light {
    font-family: 'MiSans-Light', Arial, sans-serif;
    color: gray;
}

.panel-title {
    font-family: 'MiSans-Bold', Arial, sans-serif;
    font-size: 1.5rem;
    margin-bottom: 10px;
    border-bottom: 2px solid #333;
    padding-bottom: 10px;
}

.floor-section {
    margin-top: 20px;
}

.floor-title {
    font-size: 1.2rem;
    margin-bottom: 10px;
    font-family: 'MiSans-Bold', Arial, sans-serif;
    border-bottom: 1px solid #333;
    padding-bottom: 5px;
}

.point-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.point-item {
    border-bottom: 1px solid #333;
    padding: 10px 0;
}

.point-header {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.point-thumbnail img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 5px;
}

.point-info {
    margin-left: 10px;
    flex-grow: 1;
}

.point-title {
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
    margin-bottom: 5px;
}

.point-desc {
    font-size: 0.9rem;
    color: #ccc;
    margin: 0;
}

.point-toggle-icon {
    margin-left: 10px;
}

.point-toggle-icon .arrow {
    width: 0;
    height: 0;
    border: solid #fff;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(45deg);
    transition: transform 0.3s;
}

.point-toggle-icon .arrow.down {
    transform: rotate(45deg);
}

.point-toggle-icon .arrow.up {
    transform: rotate(-135deg);
}

.sub-point-list {
    list-style: none;
    padding: 0;
    margin: 10px 0 0 0;
    border-left: 2px solid #333;
    margin-left: 20px;
}

.sub-point-item {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
}

.sub-thumbnail img {
    width: 40px;
    height: 40px;
    border-radius: 3px;
    object-fit: cover;
}

.sub-title {
    margin: 0;
    margin-left: 10px;
    font-size: 0.9rem;
    color: #fff;
}

.point-header.selected,
.sub-point-item.selected {
    background-color: #333;
    border-radius: 5px;
}

/* 动画定义 */
.expand-enter-from,
.expand-leave-to {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
}

.expand-enter-active,
.expand-leave-active {
    transition: max-height 0.3s ease, opacity 0.3s ease;
}

.expand-enter-to,
.expand-leave-from {
    max-height: 500px;
    opacity: 1;
    overflow: hidden;
}

.mobile-toggle-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 5;
    color: #fff;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    transition: width 0.5s ease, height 0.5s ease;
    will-change: width, height;
}

.mobile-toggle-btn .bar {
    display: block;
    width: 25px;
    height: 3px;
    background: #fff;
    margin: 4px 0;
}

.mobile-toggle-btn.hint-active {
    width: 200px;
}

.mobile-toggle-hint {
    margin-left: -10px;
    white-space: nowrap;
    font-size: 0.9rem;
    color: #fff;
    opacity: 0;
    transform: translateX(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    will-change: opacity, transform;
}

.mobile-toggle-btn.hint-active .mobile-toggle-hint {
    opacity: 1;
    transform: translateX(0);
    transform: translateY(0);
}

/* slide-right 动画 */
.slide-right-enter-from,
.slide-right-leave-to {
    transform: translateX(100%);
}

.slide-right-enter-active,
.slide-right-leave-active {
    transition: transform 0.3s ease;
}

.slide-right-enter-to,
.slide-right-leave-from {
    transform: translateX(0);
}

/* 桌面端底栏 */
.bottom-bar {
    position: sticky;
    bottom: 0;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.powered-by {
    color: gray;
    font-size: 0.9rem;
}

/* info按钮、弹窗动画 */
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease;
}

.fade-enter-to,
.fade-leave-from {
    opacity: 1;
}

/* 弹窗动画 */
.popup-enter-from,
.popup-leave-to {
    opacity: 0;
    transform: translateY(10px);
}

.popup-enter-active,
.popup-leave-active {
    transition: opacity 0.2s ease, transform 0.2s ease;
}

.popup-enter-to,
.popup-leave-from {
    opacity: 1;
    transform: translateY(0);
}

.info-popup {
    position: absolute;
    bottom: 70px;
    left: 20px;
    right: 20px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    z-index: 1;
}

.info-popup-content {
    position: relative;
}

.info-text {
    color: #fff;
    font-size: 0.9rem;
    white-space: pre-wrap;
    word-wrap: break-word;
    margin: 0;
}

.close-btn {
    position: absolute;
    top: -20px;
    right: -10px;
    background: none;
    border: none;
    color: #fff;
    font-size: 1.2rem;
    cursor: pointer;
}

.close-btn:hover {
    color: #ccc;
}

.buttons button {
    background: none;
    border: none;
    color: #fff;
    margin-left: 10px;
    cursor: pointer;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
}

.buttons button:hover {
    color: #ccc;
}

.buttons button:disabled {
    cursor: not-allowed;
    color: gray;
}

.info-btn.no-info {
    color: gray;
    cursor: not-allowed;
}

.info-btn.highlight span {
    animation: textHighlight 0.5s forwards;
}

@keyframes textHighlight {
    0% {
        color: white;
    }

    50% {
        color: #006aff;
    }

    100% {
        color: white;
    }
}

.info-btn span {
    display: inline-block;
    transition: transform 0.3s ease;
}

.info-btn.no-info span {
    transform: translateX(-5px);
}

@media (max-width: 768px) {
    .viewer-container {
        width: 100vw;
    }

    .right-panel {
        position: absolute;
        right: 0;
        top: 0;
    }

    .mobile-toggle-btn {
        display: block;
    }

    .info-popup {
        bottom: 70px;
    }

    .close-btn {
        display: none;
    }
}

@media (min-width: 768px) {
    .mobile-toggle-btn {
        display: none;
    }

    .info-tips {
        display: none;
    }

    .info-popup {
        z-index: 5;
    }
}
</style>