<template>
    <h1 style="color: white;">请稍候...</h1>
  </template>
  
  <script>
  export default {
    name: "RedirectPage",
    mounted() {
      // 获取地址栏的查询参数
      const queryParams = new URLSearchParams(window.location.search);
      // 从查询参数中获取目标 URL
      const targetUrl = queryParams.get("redirect");
      
      if (targetUrl) {
        // 如果目标 URL 存在，则重定向
        window.location.href = targetUrl;
      } else {
        // 如果没有目标 URL，则可以显示错误信息或跳转到默认地址
        console.error("未提供目标地址");
        window.location.href = "https://vds.pub"; // 默认跳转地址
      }
    },
  };
  </script>  